import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

tippy('.tooltip', {
    content(reference) {
        const id = reference.getAttribute('data-template');
        const template = reference.getAttribute('data-html');
        return template;
    },
    allowHTML: true,
    interactive: true,
    appendTo: () => document.body,
});