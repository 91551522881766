jQuery(document).ready(function($) {
    var ajax_object = { "ajax_url":"/wp-admin/admin-ajax.php" };

    var ui = {
        confirm: async (message) => createConfirm(message)
    }
    
    var createConfirm = (message) => {
        return new Promise((complete, failed)=>{
            $('#confirmMessage').html(message)
    
            $('#confirmYes').off('click');
            $('#confirmNo').off('click');
            
            $('#confirmYes').on('click', ()=> { $('.confirm').hide(); complete(true); });
            $('#confirmNo').on('click', ()=> { $('.confirm').hide(); complete(false); });
            
            $('.confirm').show();
        });
    }    

    // Hide or show text field depending if new list is being made
    $('select[name="existing-searches"]').on('change', function() {
        if(this.value == 'create') {
            $('.create-search').fadeIn();
        } else {
            $('.create-search').fadeOut();
        }
    });
    
    // Select all checkbox
    $("input[name='select_all_searches']").click(function(){
        $('#user-saved-searches input:checkbox').not(this).prop('checked', this.checked);
    });

    // Creating new saved search
    $(document).on("submit", "#save-search-form", function(e){
        e.preventDefault();

        var search_name = $('input[name="search-name"]').val();
        var search_total = $('input[name="search-total"]').val();
        var search_choice = $('select[name="existing-searches"]').val();
        // if(!search_choice) {
        //     var search_choice = 'create';
        // }
        
        var urlParams = new URLSearchParams(window.location.search);
        var search_filters = window.location.search;
        var searched_keywords = urlParams.get('s');
        var return_url = urlParams.get('return_url');	

        if(search_choice != '') {
            $('#save-search-form input[type="submit"]').addClass('busy');

            $.ajax({
                type: 'POST',
                dataType : "html",
                url : ajax_object.ajax_url,
                data: {
                    action: 'create_saved_search',
                    search_name: search_name,
                    search_total: search_total,
                    search_choice: search_choice,
                    search_filters: search_filters,
                    searched_keywords: searched_keywords,
                    return_url: return_url,
                },
                success: function (data) {
                    var result = JSON.parse(data);
                    $('.message').html(result.message).attr('class', 'message').addClass(result.type);

                    window.setTimeout(function(){ $('#save-search-form input[type="submit"]').removeClass('busy'); }, 200);

                    if(result.type != 'error') {
                        $('#save-search-form').fadeOut(function() {
                            $('#save-search .success-close').fadeIn();

                            $('#save-search .message').html(result.message).attr('class', 'message').addClass(result.type);
                        });
                    } else {
                        $('#save-search .message').html(result.message).attr('class', 'message').addClass(result.type);
                    }
                },
                error: function(MLHttpRequest, textStatus, errorThrown){
                    console.log(errorThrown);
                },
            });
        } else {
            $('.message').html(js_translations.saved_search_empty).attr('class', 'message').addClass('error');
        }
        return  false;
    });

    // Populate modal when edit button is clicked
    $(document).on("click", "#pin-saved-search", function(e){
        e.preventDefault();

        var el = $(this);
        var search_id = $(this).data('search_id');
        var search_name = $(this).data('search_name');

        $(el).addClass('busy-link');

        $.ajax({
            type: 'POST',
            dataType : "html",
            url : ajax_object.ajax_url,
            data: {
                action: 'pin_saved_search',
                search_id: search_id,
            },
            success: function (data) {
                var result = JSON.parse(data);
                $('.global-notification').html(result.message).attr('class', 'global-notification').addClass(result.type);

                window.setTimeout(function(){ $(el).removeClass('busy-link'); }, 200);
            },
            error: function(MLHttpRequest, textStatus, errorThrown){
                console.log(errorThrown);
            },
        });

        window.setTimeout(function(){ $('.global-notification').removeClass('success error'); }, 4000);
    });

    // Populate modal when edit button is clicked
    $(document).on("click", "#edit-saved-search", function(e){
        e.preventDefault();

        var search_id = $(this).data('search_id');
        var search_name = $(this).data('search_name');

        $('#edit-search input[name="search_name"]').val(search_name);
        $('#edit-search input[name="search_id"]').val(search_id);
    });

    // Form for editing saved search
    $(document).on("submit", "#edit-search-form", function(e){
        e.preventDefault();

        var search_name = $('input[name="search_name"]').val();
        var search_id = $('input[name="search_id"]').val();
        
        if(search_name != '') {
            $('#edit-search-form input[type="submit"]').addClass('busy');

            $.ajax({
                type: 'POST',
                dataType : "html",
                url : ajax_object.ajax_url,
                data: {
                    action: 'edit_saved_search',
                    search_name: search_name,
                    search_id: search_id,
                },
                success: function (data) {
                    var result = JSON.parse(data);
                    $('#edit-search .message').html(result.message).attr('class', 'message').addClass(result.type);

                    window.setTimeout(function(){ $('#edit-search-form input[type="submit"]').removeClass('busy'); }, 200);

                    $('[data-row_search_id="'+search_id+'"]').find('.row-search__name a').text(search_name);

                    $('#edit-search-form').fadeOut(function() {
                        $('#edit-search .success-close').fadeIn();

                        $('#edit-search .message').html(result.message).attr('class', 'message').addClass(result.type);
                    });
                },
                error: function(MLHttpRequest, textStatus, errorThrown){
                    console.log(errorThrown);
                },
            });
        } else {
            $('#edit-search .message').html(js_translations.edit_saved_search_empty).attr('class', 'message').addClass('error');
        }
        return  false;
    });

    // Delete saved search
    $(document).on("click", "#delete-saved-search", function(e){
        e.preventDefault();

        var search_ids = [];

        $('#user-saved-searches input[name="search_row"]:checked').each(function() {
            search_ids.push(this.value);
        });

        const confirmation = async () => {
            const confirm = await ui.confirm(js_translations.saved_search_delete);
                
            if(confirm){
                $.ajax({
                    type: 'POST',
                    dataType : "html",
                    url : ajax_object.ajax_url,
                    data: {
                        action: 'delete_saved_search',
                        search_ids: search_ids,
                    },
                    success: function (data) {
                        var result = JSON.parse(data);
                        $('.global-notification').html(result.message).attr('class', 'global-notification').addClass(result.type);

                        jQuery.each(search_ids, function(index, item) {
                            $('[data-row_search_id="'+item+'"]').remove();
                        });
                    },
                    error: function(MLHttpRequest, textStatus, errorThrown){
                        console.log(errorThrown);
                    },
                });

                window.setTimeout(function(){ $('.global-notification').removeClass('success error'); }, 4000);
              }
        }

        confirmation();
        
        return  false;
    });

    $(document).on("click", ".success-close, .modal__close", function(e){
        e.preventDefault();

        $(this).parent().find('form').show();
        $(this).parent().find('.message').removeClass('error success').html('');
        $(this).parent().find('.success-close').hide();
    });
});
