jQuery(document).ready(function($) {
    var ajax_object = { "ajax_url":"/wp-admin/admin-ajax.php" };

    var ui = {
        confirm: async (message) => createConfirm(message)
    }
    
    var createConfirm = (message) => {
        return new Promise((complete, failed)=>{
            $('#confirmMessage').html(message)
    
            $('#confirmYes').off('click');
            $('#confirmNo').off('click');
            
            $('#confirmYes').on('click', ()=> { $('.confirm').hide(); complete(true); });
            $('#confirmNo').on('click', ()=> { $('.confirm').hide(); complete(false); });
            
            $('.confirm').show();
        });
    }    

    $(document).on("submit", "#add-to-list-form", function(e){
        e.preventDefault();

        var list_id = $('select[name="list_id"]').val();
        var record_id = $('input[name="record_id"]').val();
        var return_url = $('input[name="return_url"]').val();

        var record_ids = [];
        record_ids.push(record_id);
        
        if(list_id != '') {
            $('#add-to-list-form input[type="submit"]').addClass('busy');

            $.ajax({
                type: 'POST',
                dataType : "html",
                url : ajax_object.ajax_url,
                data: {
                    action: 'create_list_item',
                    list_id: list_id,
                    record_ids: record_ids,
                    return_url: return_url,
                },
                success: function (data) {
                    var result = JSON.parse(data);
                    $('.message').html(result.message).attr('class', 'message').addClass(result.type);

                    window.setTimeout(function(){ $('#add-to-list-form input[type="submit"]').removeClass('busy'); }, 200);

                    $('#add-to-list-form').fadeOut(function() {
                        $('#lists .success-close').fadeIn();

                        $('#lists .message').html(result.message).attr('class', 'message').addClass(result.type);
                    });
                },
                error: function(MLHttpRequest, textStatus, errorThrown){
                    console.log(errorThrown);
                },
            });
        } else {
            $('.message').html(js_translations.list_empty).attr('class', 'message').addClass('error');
        }
        return  false;
    });

    $(document).on("click", "#remove-from-list", function(e){
        e.preventDefault();
        var el = $(this);
        var list_id = $(this).data('list_id');
        var record_id = $(this).data('record_id');
        var record_name = $(this).data('record_name');
        
        $.ajax({
            type: 'POST',
            dataType : "html",
            url : ajax_object.ajax_url,
            data: {
                action: 'remove_list_item',
                list_id: list_id,
                record_id: record_id,
                record_name: record_name,
            },
            success: function (data) {
                var result = JSON.parse(data);
                $('.global-notification').html(result.message).attr('class', 'global-notification').addClass(result.type);

                $(el).parent().parent().parent().fadeOut('slow', function() {
                    $(this).remove();
                });
            },
            error: function(MLHttpRequest, textStatus, errorThrown){
                console.log(errorThrown);
            },
        });

        window.setTimeout(function(){ $('.global-notification').removeClass('success error'); }, 4000);
        
        return  false;
    });

    $(document).on("click", "#delete-list", function(e){
        e.preventDefault();
        var el = $(this);
        var list_name = $(this).data('list_name');
        var list_id = $(this).data('list_id');
        
        const confirmation = async () => {
            const confirm = await ui.confirm(js_translations.list_delete+' "'+list_name+'"?');
                
            if(confirm){
                $.ajax({
                    type: 'POST',
                    dataType : "html",
                    url : ajax_object.ajax_url,
                    data: {
                        action: 'delete_list',
                        list_id: list_id,
                    },
                    success: function (data) {
                        var result = JSON.parse(data);
                        $('.global-notification').html(result.message).attr('class', 'global-notification').addClass(result.type);

                        $(el).parent().parent().parent().fadeOut('slow', function() {
                            $(this).remove();
                        });
                    },
                    error: function(MLHttpRequest, textStatus, errorThrown){
                        console.log(errorThrown);
                    },
                });

                window.setTimeout(function(){ $('.global-notification').removeClass('success error'); }, 4000);
            }
        }

        confirmation();
        
        return  false;
    });

    // Display add to list select if a checkbox is checked
    $(document).on("change", 'input[name="add-to-list-item"]', function(e){
        if($('input[name="add-to-list-item"]:checked').length > 0) {
            if(!$('.multi-add-to-list').hasClass('visible')) {
                $('.multi-add-to-list').fadeIn().addClass('visible');
            }
        } else {
            $('.multi-add-to-list').fadeOut().removeClass('visible');
        }
    });


    // For selecting multiple items on search results page
    $(document).on("change", "#multi-add-to-list-form select", function(e){
        e.preventDefault();

        var list_id = $('#multi-add-to-list-form select[name="list_id"]').val();
        var return_url = $('#multi-add-to-list-form input[name="return_url"]').val();

        // Gets each checked record id
        var record_ids = [];
        $('input[name="add-to-list-item"]').each(function () {
            var thisVal = (this.checked ? $(this).val() : "");

            if(thisVal != '') {
                record_ids.push(thisVal);
            }
        });
    
        if(list_id != '' && list_id != 'create') {
            $('#multi-add-to-list-form').addClass('busy-select');

            $.ajax({
                type: 'POST',
                dataType : "html",
                url : ajax_object.ajax_url,
                data: {
                    action: 'create_list_item',
                    list_id: list_id,
                    record_ids: record_ids,
                    return_url: return_url,
                },
                success: function (data) {
                    var result = JSON.parse(data);
                    $('.global-notification').html(result.message).attr('class', 'global-notification').addClass(result.type);

                    window.setTimeout(function(){ $('.global-notification').removeClass('success error'); }, 4000);

                    $('#multi-add-to-list-form').removeClass('busy-select');
                },
                error: function(MLHttpRequest, textStatus, errorThrown){
                    console.log(errorThrown);
                },
            });
        } else if(list_id == 'create') {
            //$('.global-notification').html('Creating list').attr('class', 'global-notification').addClass('error');

            $('#create-new-list').addClass('is-active');
            $('body').addClass('modal-open');
        } else {
            $('.global-notification').html(js_translations.list_empty).attr('class', 'global-notification').addClass('error');

            window.setTimeout(function(){ $('.global-notification').removeClass('success error'); }, 4000);
        }

        $('#multi-add-to-list-form select').val('');

        return  false;
    });

    // For selecting multiple items on search results page
    $(document).on("submit", "#create-new-list-form", function(e){
        e.preventDefault();
        var list_name = $('#create-new-list-form input[name="list_name"]').val();
        var return_url = $('#create-new-list-form input[name="return_url"]').val();

        // Gets each checked record id
        var record_ids = [];
        $('input[name="add-to-list-item"]').each(function () {
            var thisVal = (this.checked ? $(this).val() : "");

            if(thisVal != '') {
                record_ids.push(thisVal);
            }
        });
        
        if(list_name == '') {
            $('#create-new-list .message').html(js_translations.list_name_empty).attr('class', 'message').addClass('error');
        } else if(record_ids == '') {
            $('#create-new-list .message').html(js_translations.list_items_empty).attr('class', 'message').addClass('error');
        } else {
            $('#create-new-list-form input[type="submit"]').addClass('busy');

            $.ajax({
                type: 'POST',
                dataType : "html",
                url : ajax_object.ajax_url,
                data: {
                    action: 'create_new_list',
                    list_name: list_name,
                    record_ids: record_ids,
                },
                success: function (data) {
                    var result = JSON.parse(data);

                    window.setTimeout(function(){ $('#create-new-list-form input[type="submit"]').removeClass('busy'); }, 200);

                    $('#create-new-list-form').fadeOut(function() {
                        $('#create-new-list .success-close').fadeIn();

                        $('#create-new-list .message').html(result.message).attr('class', 'message').addClass(result.type);
                    });

                    $('#multi-add-to-list-form select option').eq(0).after($("<option></option>").val(result.list_id).text(list_name));   
                },
                error: function(MLHttpRequest, textStatus, errorThrown){
                    console.log(errorThrown);
                },
            });
        }

        return  false;
    });

    $(document).on("click", ".select-all", function(e){
        e.preventDefault();
        $('.view-panel.active input:checkbox').each(function() {
            this.checked = true; 
         }).change();
    });

});
