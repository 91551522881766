// facet accordions
var acc = document.getElementsByClassName("accordion");
var i;

for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.display === "block") {
            panel.style.display = "none";
        } else {
            panel.style.display = "block";
        }
    });
}

// Easy function for setting cookies
function setCookie(cName, cValue, expDays) {
    let date = new Date();
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}

// Easy function for getting cookies
function getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded .split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
}

// Function for changing search results view
var viewGrid = document.querySelector(".layout-view .grid");
var viewList = document.querySelector(".layout-view .rows");
//var viewMap = document.querySelector(".layout-view .map");

if(viewList && viewGrid) {
    // Change to grid view
    function setView(viewType) {
        document.querySelectorAll(".layout-view a").forEach((item) => {
            item.classList.remove("active");
        });
        document.querySelector(".layout-view ."+viewType).classList.add("active");

        document.querySelectorAll(".view-panel").forEach((item) => {
            item.classList.remove("active");
        });
        document.querySelector(".object-"+viewType).classList.add("active");

        var queryParams = new URLSearchParams(window.location.search);
        queryParams.set("view", viewType);
        history.replaceState(null, null, "?"+queryParams.toString());

        setCookie('view', viewType, 30);
    }

    window.addEventListener("load", function() { 
        // Check cookie and then set appropriately
        var cookie = getCookie('view');
        if(cookie) {
            setView(cookie);
        } else {
            setView('grid');
        }

        // Change view on icon click
        viewList.addEventListener('click', function(viewChangeEvent) {
            setView('rows');
        });

        viewGrid.addEventListener('click', function(viewChangeEvent) {			
            setView('grid');
        });

        // viewMap.addEventListener('click', function(viewChangeEvent) {			
        //     setView('map');
        // });
    });
}

// Object record details tabs
window.addEventListener("load", function() {
	// store tabs variable
	var myTabs = document.querySelectorAll(".artwork-details-tabs__menu a");
	var myTabsSelect = document.querySelector(".artwork-details-tabs__select");

    if(myTabsSelect && myTabs) {
        // The mobile version has a select menu to change tabs
        myTabsSelect.addEventListener('change', function(selectChangeEvent) {
            var myContentPanes = document.querySelectorAll(".artwork-details-tabs__panel");
            for (i = 0; i < myContentPanes.length; i++) {
                myContentPanes[i].classList.remove("active");
            }
            var anchorReference = selectChangeEvent.target;
            var activePaneId = anchorReference.value;
            var activePane = document.querySelector('#'+activePaneId);
            activePane.classList.add("active");
        });
    
        function myTabClicks(tabClickEvent) {
            for (var i = 0; i < myTabs.length; i++) {
                myTabs[i].classList.remove("active");
            }
            var clickedTab = tabClickEvent.currentTarget;
            clickedTab.classList.add("active");
            tabClickEvent.preventDefault();
            var myContentPanes = document.querySelectorAll(".artwork-details-tabs__panel");
            for (i = 0; i < myContentPanes.length; i++) {
                myContentPanes[i].classList.remove("active");
            }
            var anchorReference = tabClickEvent.target;
            var activePaneId = anchorReference.getAttribute("href");
            var activePane = document.querySelector(activePaneId);
            activePane.classList.add("active");
        }

        for (i = 0; i < myTabs.length; i++) {
            myTabs[i].addEventListener("click", myTabClicks)
        }
    }
});

try{
    var params = (new URL(document.location)).searchParams;
    var s = params.get("s");
    var deparam = decodeURIComponent(params);
    if(s==null){	//no search param, see if it's encoded instead
        var durl = window.location.protocol+"//"+window.location.hostname+window.location.pathname+"?"+deparam;
        let params = (new URL(durl)).searchParams;
        s = params.get("s").replace("=","");
    }
    document.getElementById("searchBox").value=s;
}catch{

}

jQuery(document).ready(function(){
    jQuery(".tmp_set_nav button").click(function(){
        jQuery(".object-search").slideToggle();
    });

    jQuery(".search-filters").hide();
    jQuery(".expand-search").click(function(){
        jQuery(this).toggleClass('active');
        jQuery(".search-filters").slideToggle();

        if(jQuery(this).hasClass('active')){
            jQuery(this).html("<svg xmlns='http://www.w3.org/2000/svg' width='22' height='18' fill='none' viewBox='0 0 22 18'><path stroke='currentcolor' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M8 14.199c0 1.6-1.1 2.7-2.7 2.7h-1m3.7-2.6a2.6 2.6 0 0 0 2.7 2.7h1M8 3.801c0-1.6 1.1-2.7 2.7-2.7h1M8 3.701a2.6 2.6 0 0 0-2.7-2.7h-1'/><path stroke='currentcolor' stroke-width='1.5' d='M8 2.999v12'/><path stroke='currentcolor' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M12.1 4.899h6.8c1.1 0 2.1 1 2.1 2.1v4c0 1.1-.9 2.1-2.1 2.1h-6.8M3.8 13.099h-.7c-1.1 0-2.1-1-2.1-2.1v-4c0-1.1 1-2.1 2.1-2.1h.7'/></svg> Close refine search");           
        } else {
            jQuery(this).html("<svg xmlns='http://www.w3.org/2000/svg' width='22' height='18' fill='none' viewBox='0 0 22 18'><path stroke='currentcolor' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M8 14.199c0 1.6-1.1 2.7-2.7 2.7h-1m3.7-2.6a2.6 2.6 0 0 0 2.7 2.7h1M8 3.801c0-1.6 1.1-2.7 2.7-2.7h1M8 3.701a2.6 2.6 0 0 0-2.7-2.7h-1'/><path stroke='currentcolor' stroke-width='1.5' d='M8 2.999v12'/><path stroke='currentcolor' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M12.1 4.899h6.8c1.1 0 2.1 1 2.1 2.1v4c0 1.1-.9 2.1-2.1 2.1h-6.8M3.8 13.099h-.7c-1.1 0-2.1-1-2.1-2.1v-4c0-1.1 1-2.1 2.1-2.1h.7'/></svg> Refine search");
        }
    });
});